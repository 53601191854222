import * as Jwt from "jsonwebtoken";
import AppEnv from "@/consts/AppEnv";
import RandomNumberSettingModel from "@/models/RandomNumberSettingModel";
import RandomNumberItemModel from "@/models/RandomNumberItemModel";

const generalSetting = AppEnv.getGeneralSetting();
const jwtConfig = AppEnv.getJwtConfig();

export default class JwtUtil {
  /**
   * createSecretJwt
   * @param {RandomNumberSettingModel} setting
   * @param {RandomNumberItemModel[]} items
   * @param {string} password
   * @param {string} secretHash
   * @returns {string}
   */
  static csj(
    setting,
    items,
    password,
    secretHash = `${generalSetting.appUrl}__${jwtConfig.jwtSecret}`
  ) {
    const hashStr = Jwt.sign(
      {
        setting: setting,
        items: items,
      },
      password
    );

    return Jwt.sign(
      {
        isUsePassword: true,
        hashStr: hashStr,
      },
      secretHash
    );
  }

  /**
   * createRandomNumberJwt
   * @param {RandomNumberSettingModel} setting
   * @param {RandomNumberItemModel[]} items
   * @param {string} secretHash
   * @return {string}
   */
  static crnj(
    setting,
    items,
    secretHash = `${generalSetting.appUrl}__${jwtConfig.jwtSecret}`
  ) {
    return Jwt.sign(
      {
        setting: setting,
        items: items,
      },
      secretHash
    );
  }

  /**
   * decodeJwt
   * @param {string} jwtStr
   * @param {string} secretHash
   * @return {{
   *   setting: RandomNumberSettingModel,
   *   items: RandomNumberItemModel[]
   * } | {
   *   hashStr: string,
   *   isUsePassword : boolean
   * }}
   */
  static dj(
    jwtStr,
    secretHash = `${generalSetting.appUrl}__${jwtConfig.jwtSecret}`
  ) {
    try {
      const decoded = Jwt.verify(jwtStr, secretHash);

      if (decoded.isUsePassword) {
        return {
          isUsePassword: decoded.isUsePassword,
          hashStr: decoded.hashStr || "",
        };
      }

      const result = {};

      if (decoded.setting) {
        result.setting = new RandomNumberSettingModel(decoded.setting);
      }

      if (decoded.items && Array.isArray(decoded.items)) {
        result.items = [];
        decoded.items.forEach((item) => {
          result.items.push(new RandomNumberItemModel(item));
        });
      }

      return result;
    } catch (e) {
      return {
        setting: new RandomNumberSettingModel(),
        items: [],
        hasError: true,
      };
    }
  }

  /**
   * createJwtStr
   * @param str
   * @param secretHash
   * @returns {*}
   */
  static cjs(
    str,
    secretHash = `${generalSetting.appUrl}__${jwtConfig.jwtSecret}`
  ) {
    return Jwt.sign(str, secretHash);
  }
}
