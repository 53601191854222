import queryString from "query-string";
import AppEnv from "@/consts/AppEnv";
import JwtUtil from "@/util/JwtUtil";
import StringEncryptUtil from "@/util/StringEncryptUtil";
import CompressStrUtil from "@/util/CompressStrUtil";
import RandomNumberSettingModel from "@/models/RandomNumberSettingModel";
import SM from "@/helper/SM";
import { eak, akb } from "@/models/DomainModel";

const generalSetting = AppEnv.getGeneralSetting();

/**
 * UrlHelper
 */
export default class UrlHelper {
  /**
   * get comain name
   * ドメイン名を取得する
   * @returns {string}
   */
  static gcn() {
    return `${window.location.protocol}${window.location.host}`;
  }

  /**
   * get compress str
   * @param {RandomNumberSettingModel} setting
   * @param {RandomNumberItemModel[]} items
   * @param {RandomNumberSecretModel} secret
   * @return {string}
   */
  static gcs(setting, items, secret) {
    try {
      eak();
      let hashStr = "";
      if (secret.isUsePassword) {
        hashStr = JwtUtil.csj(
          setting,
          items,
          StringEncryptUtil.gpw(secret.password, SM.gps()),
          SM.gjps()
        );
      } else {
        hashStr = JwtUtil.crnj(setting, items, SM.gjns());
      }

      const enCryptoStr = StringEncryptUtil.ges(
        hashStr,
        SM.gek(),
        SM.gebk(),
        SM.gem(),
        SM.gee()
      );

      const compressedStr = CompressStrUtil.deflate(enCryptoStr);
      akb();
      return SM.uekfi(compressedStr);
    } catch (e) {
      // console.error(e);
      return "";
    }
  }

  /**
   * get decompressed obj
   * @param {{q: string}} params
   * @return {{
   *   setting: RandomNumberSettingModel,
   *   items: RandomNumberItemModel[]
   * } | {
   *   hashStr: string,
   *   isUsePassword : boolean
   * }}
   */
  static gdco(params) {
    if (params == null || params.q == null || params.q === "") {
      return {
        setting: new RandomNumberSettingModel(),
        items: [],
      };
    }

    try {
      const q = params.q || "";
      const bk = SM.udkfi(q);
      const decompressedStr = CompressStrUtil.inflate(bk);

      const deCryptoStr = StringEncryptUtil.gds(
        decompressedStr,
        SM.gek(),
        SM.gebk(),
        SM.gem(),
        SM.gee()
      );
      return JwtUtil.dj(deCryptoStr, SM.gjns());
    } catch (e) {
      // console.error(e);
      return {
        setting: new RandomNumberSettingModel(),
        items: [],
      };
    }
  }

  /**
   * get absolute url
   * @param compressStr
   * @returns {string}
   */
  static gau(compressStr) {
    return `${generalSetting.appUrl}?${UrlHelper.ghq(compressStr)}`;
  }
  /**
   * get share url
   * @param {string} compressStr
   */
  static gsu(compressStr) {
    return `/?${UrlHelper.ghq(compressStr)}`;
  }

  /**
   * get home query
   * @param q
   * @returns {string|string}
   */
  static ghq(q) {
    return queryString.stringify({
      q: q,
    });
  }
}
