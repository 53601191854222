import AppEnv from "@/consts/AppEnv";

const arr2 = "quAQaDyRWFd36vlUEbhjkOBwizpYM72TgVxK0f4St5CImZePN1JHXGrL9o8scn";
const arr3 = "od8sXrLW3tKU24yqxhRunwJCAOHpeYvTB0bFSzgaim9kMNPjlfZVED75Q16IcG";

export function pro() {
  try {
    const host = window.location.hostname;

    return AppEnv.getSecuritySetting().d === host;
  } catch (e) {
    return false;
  }
}

export function np() {
  try {
    /**
     * @type {string}
     */
    const host = window.location.hostname;

    const arrr = arr2.split("");
    const hb = host.split("").map((val, i) => {
      return Number(val.charCodeAt(0)) + Number(arrr[i].charCodeAt(0));
    });

    // NOTE: domain change
    const val = [
      229,
      218,
      174,
      193,
      208,
      182,
      218,
      196,
      208,
      115,
      212,
      148,
      169,
      233,
      227,
      196,
      183,
      198,
      149,
      209,
      208,
      189,
      167,
      233,
      202,
      238,
      223,
      203,
      123,
      167,
      147,
      202,
      204,
      131,
      236,
      179,
      149,
      147,
      171,
      180,
      237,
      99,
      186,
      184,
      223,
      197,
    ];

    return val.every((item, index) => item === hb[index]);
  } catch (e) {
    return false;
  }
}

export function tt() {
  try {
    /**
     * @type {string}
     */
    const host = window.location.hostname;

    const arrr = arr3.split("");
    const hb = host.split("").map((val, i) => {
      return Number(val.charCodeAt(0)) + Number(arrr[i].charCodeAt(0));
    });

    // NOTE: domain change
    const val = [
      227,
      201,
      165,
      227,
      199,
      228,
      173,
      201,
      172,
      161,
      187,
      182,
      165,
      167,
      240,
      224,
      234,
      204,
      127,
      220,
      211,
      229,
      175,
      181,
      162,
      195,
      183,
      226,
      147,
      201,
      215,
      202,
      167,
      93,
      214,
      174,
      184,
      167,
      222,
      194,
      226,
      155,
      176,
      218,
      191,
      185,
    ];

    return val.every((item, index) => item === hb[index]);
  } catch (e) {
    return false;
  }
}
