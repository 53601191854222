export default class RandomNumberSecretModel {
  constructor(init) {
    // 共有にパスワードを使用するかどうか
    this.isUsePassword = false;

    // パスワード
    this.password = "";
    this.set(init);
  }

  set(init) {
    Object.assign(this, init);
  }
}
