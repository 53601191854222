<template>
  <v-card id="random-number-list organisms">
    <v-card-text>
      <v-simple-table fixed-header height="452px">
        <template>
          <thead>
            <tr>
              <th class="text-left">生成値</th>
              <th class="text-left">メモ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in randomNumberItems"
              :key="item.id"
              class="table-list-item"
            >
              <!-- value -->
              <td>
                <v-text-field
                  v-model="item.value"
                  placeholder="生成された文字"
                  :type="setting.isViewpassword ? 'text' : 'password'"
                >
                  <v-tooltip slot="append" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          $emit('onClickListItemValueCopy', {
                            item: item,
                            index: index
                          })
                        "
                      >
                        <v-icon>
                          fa-copy
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>クリップボードにコピー</span>
                  </v-tooltip>
                </v-text-field>
              </td>

              <!-- memo -->
              <td>
                <v-text-field v-model="item.note" placeholder="メモを記入">
                </v-text-field>
              </td>

              <!-- icon -->
              <td>
                <v-tooltip slot="append" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      slot="append"
                      text
                      icon
                      color="red lighten-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        $emit('onClickListItemDelete', {
                          item: item,
                          index: index
                        })
                      "
                    >
                      <v-icon>
                        fa-trash-alt
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>削除する</span>
                </v-tooltip>
              </td>
            </tr>

            <!-- 追加 -->
            <tr class="table-list-item" v-if="items.length > 0">
              <td>
                <v-btn tile color="success" @click="$emit('onClickAddItem')">
                  <v-icon left>
                    fa-plus-circle
                  </v-icon>
                  追加
                </v-btn>
              </td>
              <td colspan="2">
                <v-switch
                  v-model="setting.isViewpassword"
                  label="パスワードを表示する"
                ></v-switch>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import RandomNumberSettingModel from "@/models/RandomNumberSettingModel";

export default {
  props: {
    setting: {
      type: RandomNumberSettingModel,
      required: true
    },

    items: {
      type: Array,
      required: true
    }
  },

  computed: {
    /**
     * @returns {RandomNumberItemModel[]}
     */
    randomNumberItems() {
      if (this && this.items) {
        return this.items;
      }

      return [];
    }
  }
};
</script>

<style lang="scss">
.table-list-item {
  td {
    > div {
    }
  }
}
</style>
