export default class RandomNumberItemModel {
  constructor(init) {
    this.id = 0;
    this.value = "";
    this.note = "";

    this.set(init);
  }

  set(init) {
    Object.assign(this, init);
  }
}
