<template>
  <v-app-bar app dark id="app-header" color="#2F80ED">
    <div class="d-flex align-center">
      <router-link to="/">
        <v-icon>
          fa-industry
        </v-icon>
        <span class="project-name">{{ configGeneral.projectName || "" }}</span>
      </router-link>
    </div>
    <v-spacer></v-spacer>

    <div>
      <router-link to="/about">About</router-link>
    </div>
  </v-app-bar>
</template>

<script>
import AppConfigMixin from "@/mixins/AppConfigMixin";

export default {
  mixins: [AppConfigMixin],
  components: {}
};
</script>

<style lang="scss">
#app-header {
  .project-name {
    margin-left: 1em;
  }

  a {
    color: white;
    text-decoration-line: none;
    &:hover {
      color: blue;
    }
  }
}
</style>
