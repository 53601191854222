import { ga, ge, gi, gs } from "@/util/ColorPickUtil";
import { np } from "@/util/NumberPickUtil";
import DatePickUtil from "@/util/DatePickUtil";
import ArrayUtil from "@/util/ArrayUtil";
import RandomNumberConfig from "@/config/RandomNumberConfig";
import { mix, mixes } from "@/mixins/GlobalMixin";

const arrLen = 1000;
// const nArr = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nArr = "SWJCQZfpkOFUo43VP8x5zMb0uyXDdEs7qIY9hvAGwnaKc6m1BgliLTetRjHNr2";
const bArr = "8RZTtSom0zhcg4pBKHkrM7WviJsqP9ejflUVwNAaxOnYyGF5uXIEb32d1CQL6D-";

export default class SM {
  /**
   * getJwtNormalSecret
   * @returns {string}
   */
  static gjns() {
    if (!mix()) return "";
    const cs = DatePickUtil.gdc();

    const an1 = gs[ArrayUtil.getIndex(gs.length, cs.lNum)];
    const an2 = gs[ArrayUtil.getIndex(gs.length, cs.nNum)];
    const an3 = gs[ArrayUtil.getIndex(gs.length, cs.qNum)];
    const an4 = gs[ArrayUtil.getIndex(gs.length, cs.qNum)];
    const en1 = ge[ArrayUtil.getIndex(ge.length, cs.lNum)];
    const en2 = ge[ArrayUtil.getIndex(ge.length, cs.nNum)];
    const en3 = ge[ArrayUtil.getIndex(ge.length, cs.qNum)];
    const en4 = ge[ArrayUtil.getIndex(ge.length, cs.zNum)];

    if (!mixes()) return "";

    return `${an2}${an4}${an3}${an1}${en4}${en2}${en3}${en1}`;
  }

  /**
   * getJwtPasscodeSecret
   * @returns {string}
   */
  static gjps() {
    if (!mix()) return "";
    const cs = DatePickUtil.gdc();

    const an1 = gs[ArrayUtil.getIndex(gs.length, cs.lNum)];
    const an2 = gs[ArrayUtil.getIndex(gs.length, cs.nNum)];
    const an3 = gs[ArrayUtil.getIndex(gs.length, cs.qNum)];
    const an4 = gs[ArrayUtil.getIndex(gs.length, cs.qNum)];
    const en1 = ge[ArrayUtil.getIndex(ge.length, cs.lNum)];
    const en2 = ge[ArrayUtil.getIndex(ge.length, cs.nNum)];
    const en3 = ge[ArrayUtil.getIndex(ge.length, cs.qNum)];
    const en4 = ge[ArrayUtil.getIndex(ge.length, cs.zNum)];

    if (!mixes()) return "";
    return `${an2}${an4}${an3}${an1}${en4}${en2}${en3}${en1}`;
  }

  /**
   * getEncryptKey
   * @returns {string}
   */
  static gek() {
    if (!mix()) return "";
    const cs = DatePickUtil.gdc();

    const an1 = ga[ArrayUtil.getIndex(ga.length, cs.lNum)];
    const an3 = ga[ArrayUtil.getIndex(ga.length, cs.nNum)];

    if (!mixes()) return "";
    return `${an3}${an1}`;
  }

  /**
   *  getEncryptBufferKey
   * @returns {string}
   */
  static gebk() {
    if (!mix()) return "";
    const cs = DatePickUtil.gdc();
    const an1 = gi[ArrayUtil.getIndex(gi.length, cs.lNum)];
    const an2 = gi[ArrayUtil.getIndex(gi.length, cs.nNum)];
    const an3 = gi[ArrayUtil.getIndex(gi.length, cs.qNum)];
    const an4 = gi[ArrayUtil.getIndex(gi.length, cs.zNum)];

    if (!mixes()) return "";
    return `${an1}${an4}${an2}${an3}`;
  }

  /**
   * getEncryptMethod
   * @returns {string}
   */
  static gem() {
    if (!mix()) return "";
    const _1 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[1])];
    const _2 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[3])];
    const _3 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[11])];
    const _4 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[8])];
    const _5 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[9])];
    const _6 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[0])];
    const _7 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[5])];
    const _8 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[8])];
    const _9 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[10])];
    const _10 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[4])];
    const _11 = bArr[bArr.indexOf(RandomNumberConfig.aaaStr[10])];
    const result = `${_1}${_2}${_3}${_4}${_5}${_6}${_7}${_8}${_9}${_10}${_11}`;

    if (!mixes()) return "";
    return result;
  }

  /**
   * getEncryptEncoding
   * @returns {string}
   */
  static gee() {
    if (!mix()) return "";
    const _1 = nArr[nArr.indexOf(RandomNumberConfig.aaaStr[13])];
    const _2 = nArr[nArr.indexOf(RandomNumberConfig.aaaStr[3])];
    const _3 = nArr[nArr.indexOf(RandomNumberConfig.aaaStr[2])];

    if (!mixes()) return "";
    return `${_1}${_2}${_3}`;
  }

  /**
   * getPassCodeSale
   * @returns {string}
   */
  static gps() {
    if (!mix()) return "";
    const cs = DatePickUtil.gdc();
    const an1 = gi[ArrayUtil.getIndex(gi.length, cs.lNum)];
    const an2 = gs[ArrayUtil.getIndex(gs.length, cs.nNum)];
    const an3 = ga[ArrayUtil.getIndex(ga.length, cs.qNum)];
    const an4 = ge[ArrayUtil.getIndex(ge.length, cs.zNum)];

    if (!mixes()) return "";
    return `${an1}${an4}${an2}${an3}`;
  }

  /**
   * urlEncryptKeyFromInside
   * @param {string} q
   */
  static uekfi(q) {
    if (!mix()) return "";
    let s = q;
    const cs = DatePickUtil.gdc();
    const st = 20,
      ed = 20;
    const qLen = q.length - st - ed;

    const an1 = np[ArrayUtil.getIndex(np.length, cs.lNum)];
    const an2 = np[ArrayUtil.getIndex(np.length, cs.nNum)];
    const an3 = np[ArrayUtil.getIndex(np.length, cs.qNum)];
    const an4 = np[ArrayUtil.getIndex(np.length, cs.zNum)];

    [...new Array(arrLen)].fill(0).forEach((_, aIdx) => {
      const abStr = aIdx % 2 === 0 ? `${an1}${an2}` : `${an2}${an4}`;
      abStr.split("").forEach((val, index) => {
        const num = Math.floor(
          Number(val) * (index + 1) * (aIdx + 2) + (index + 3) + Math.sin(aIdx)
        );
        const idx = ArrayUtil.getIndex(qLen, num) + st;
        const c = s[idx];
        // const cb = c.charCodeAt(0);

        if (typeof s === "string" || s instanceof String) {
          const bStr = aIdx % 2 === 0 ? `${an3}${an4}` : `${an1}${an3}`;
          let r = "a";
          let inc = Number(bStr[index]);

          if (!nArr.includes(c)) return;

          const nI = nArr.indexOf(c);
          let cI = nI + inc;
          cI = cI >= nArr.length ? cI - nArr.length : cI;
          // r = String.fromCharCode(cb + inc * direction);
          r = nArr[cI];
          s = ArrayUtil.setCharAt(s, idx, r);
        }
      });
    });

    if (!mixes()) return "";
    return s;
  }

  /**
   * urlDecryptKeyFromInside
   * @param {string} q
   */
  static udkfi(q) {
    if (!mix()) return "";
    let s = q;
    const cs = DatePickUtil.gdc();
    const st = 20,
      ed = 20;
    const qLen = q.length - st - ed;

    const an1 = np[ArrayUtil.getIndex(np.length, cs.lNum)];
    const an2 = np[ArrayUtil.getIndex(np.length, cs.nNum)];
    const an3 = np[ArrayUtil.getIndex(np.length, cs.qNum)];
    const an4 = np[ArrayUtil.getIndex(np.length, cs.zNum)];

    [...new Array(arrLen)].fill(0).forEach((_, aIdx) => {
      const _aIdx = arrLen - aIdx - 1;
      const abStr = _aIdx % 2 === 0 ? `${an1}${an2}` : `${an2}${an4}`;

      const abLen = abStr.split("").length;
      abStr.split("").forEach((val, index) => {
        const _index = abLen - index - 1;
        const _val = abStr[_index];
        const num = Math.floor(
          Number(_val) * (_index + 1) * (_aIdx + 2) +
            (_index + 3) +
            Math.sin(_aIdx)
        );
        const idx = ArrayUtil.getIndex(qLen, num) + st;
        const c = s[idx];
        // const cb = c.charCodeAt(0);

        if (typeof s === "string" || s instanceof String) {
          const bStr = _aIdx % 2 === 0 ? `${an3}${an4}` : `${an1}${an3}`;
          let r = "a";
          let inc = Number(bStr[_index]);

          if (!nArr.includes(c)) return;

          const nI = nArr.indexOf(c);
          let cI = nI - inc;
          cI = cI < 0 ? nArr.length + cI : cI;
          // r = String.fromCharCode(cb + inc * direction);
          r = nArr[cI];
          s = ArrayUtil.setCharAt(s, idx, r);
        }
      });
    });

    if (!mixes()) return "";
    return s;
  }
}
