<template>
  <v-card id="random-number-footer">
    <v-card-text>
      <!-- パスワード設定 -->
      <v-row class="password-setting-row">
        <v-col sm="6">
          <v-checkbox
            v-model="secretModel.isUsePassword"
            label="パスワード付きで共有する"
            required
          ></v-checkbox>
        </v-col>
        <v-col sm="6">
          <v-text-field
            type="password"
            v-model="secretModel.password"
            :value="shareUrl"
            label="復号用パスワード"
            placeholder="復号用パスワード"
            :disabled="!secretModel.isUsePassword"
            :readonly="!secretModel.isUsePassword"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- 共有URL -->
      <v-row align-content="center" justify="center">
        <v-col sm="3" align-self="start">
          <v-btn class="mr-4" @click="submit" color="primary">
            <v-icon>
              fa-share-square
            </v-icon>
            URLで共有
          </v-btn>
        </v-col>

        <v-col sm="9" align-self="start" class="share-url-input">
          <v-text-field
            :value="shareUrl"
            label="共有 URL"
            placeholder="共有 URL"
            outlined
            readonly
            append-icon="fa-copy"
            @click:append="$emit('onClickShareUrlCopy', shareUrl)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="footer-dl-row">
        <v-col sm="12" align-self="end">
          <v-btn class="mr-4" @click="$emit('onClickDownload')" color="primary">
            <v-icon>
              fa-file-download
            </v-icon>
            ダウンロード
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import RandomNumberSettingModel from "@/models/RandomNumberSettingModel";
import RandomNumberSecretModel from "@/models/RandomNumberSecretModel";

export default {
  props: {
    setting: {
      type: RandomNumberSettingModel,
      required: true
    },
    secretModel: {
      type: RandomNumberSecretModel,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    shareUrl: {
      type: String,
      default: ""
    }
  },

  methods: {
    submit() {
      this.$emit("onClickUrlShare", this.getCurrentSetting());
    },

    getCurrentSetting() {
      return {
        isUsePassword: this.secretModel.isUsePassword,
        password: this.secretModel.password
      };
    }
  }
};
</script>

<style lang="scss">
#random-number-footer {
  .share-url-input {
    padding-bottom: 0px;
  }

  .password-setting-row {
    > div {
      padding-top: 0px;
    }
  }

  .v-text-field__details {
    display: none;
  }

  .v-messages {
    display: none;
  }

  .footer-dl-row {
    > div:last-child {
      display: flex;
      justify-content: flex-end;

      button {
        margin-right: 0px !important;
      }
    }
  }
}
</style>
