import * as cryptoJs from "crypto";
import AppEnv from "@/consts/AppEnv";
import crypto from "crypto";
import { tt } from "@/plugins/appPlugin";

const cryptoSetting = AppEnv.getCryptConfig();

export default class StringEncryptUtil {
  /**
   * getEncryptedString
   * @param {string} str
   * @param {string} encryptionKey
   * @param {string} bufferKey
   * @param {string} encryptMethod
   * @param {string} encoding
   *
   * @returns {string}
   */
  static ges(
    str,
    encryptionKey = `${cryptoSetting.secret}`,
    bufferKey = `${cryptoSetting.bufferKey}`,
    encryptMethod = `${cryptoSetting.method}`,
    encoding = `${cryptoSetting.encoding}`
  ) {
    if (!tt()) return "";
    const iv = Buffer.from(bufferKey);
    const cipher = cryptoJs.createCipheriv(
      encryptMethod,
      Buffer.from(encryptionKey),
      iv
    );
    const encrypted = Buffer.concat([cipher.update(str), cipher.final()]);
    return encrypted.toString(encoding);
  }

  /**
   * getDecryptedString
   * @param {string} encrypted
   * @param {string} encryptionKey
   * @param {string} bufferKey
   * @param {string} encryptMethod
   * @param {string} encoding
   * @returns {string}
   */
  static gds(
    encrypted,
    encryptionKey = `${cryptoSetting.secret}`,
    bufferKey = `${cryptoSetting.bufferKey}`,
    encryptMethod = `${cryptoSetting.method}`,
    encoding = `${cryptoSetting.encoding}`
  ) {
    if (!tt()) return "";
    const iv = Buffer.from(bufferKey);
    const encryptedText = Buffer.from(encrypted, encoding);
    const decipher = cryptoJs.createDecipheriv(
      encryptMethod,
      Buffer.from(encryptionKey),
      iv
    );
    const decrypted = Buffer.concat([
      decipher.update(encryptedText),
      decipher.final(),
    ]);

    return decrypted.toString();
  }

  /**
   * getPassWordHash
   * @param {string} password
   * @param {string} salt
   * @param {number} iterations
   * @param {number} keylen
   * @param {string} digest
   * @param { "ascii" | "utf8" | "utf-8" | "utf16le" | "ucs2" | "ucs-2" | "base64" | "latin1" | "binary" | "hex" | undefined} encoding
   * @returns {string}
   */
  static gpw(
    password,
    salt = `${cryptoSetting.passCodeSalt}`,
    iterations = 1000,
    keylen = 64,
    digest = "sha512",
    encoding = "base64"
  ) {
    if (!tt()) return "";
    return crypto
      .pbkdf2Sync(password, salt, iterations, keylen, digest)
      .toString(encoding);
  }
}
