export default {
  GenerateIncludes: {
    NUMBER: 0,
    LOWER_CASE: 1,
    UPPER_CASE: 2,
    SYMBOL: 3,
  },

  GenerateBasesStrs: [
    "0123456789",
    "abcdefghijklmnopqrstuvwxyz",
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    "!\"#$%&'()*+,-./:;<=>?@[]^_{|}~",
  ],

  aaaStr: [
    "5",
    "a",
    "x",
    "e",
    "b",
    "6",
    "z",
    "q",
    "-",
    "2",
    "c",
    "s",
    "4",
    "h",
  ],
};
