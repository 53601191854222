export default class ArrayUtil {
  static getIndex(length, num) {
    return num % length;
  }

  static setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }
}
