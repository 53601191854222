import RandomNumberConfig from "@/config/RandomNumberConfig";
import * as cryptoJs from "crypto";
import RandomNumberHelper from "@/helper/RandomNumberHelper";
import RandomNumberItemModel from "@/models/RandomNumberItemModel";
// const cryptoJs = require("crypto");

export default class RandomNumberSettingModel {
  constructor(init) {
    this.generateIncludes = [
      RandomNumberConfig.GenerateIncludes.NUMBER,
      RandomNumberConfig.GenerateIncludes.LOWER_CASE,
      RandomNumberConfig.GenerateIncludes.UPPER_CASE,
    ];

    // ランダム出力で使用する文字の種類
    this.generateBaseStrs = RandomNumberHelper.getIncludesStr(
      this.generateIncludes
    );

    // 桁数
    this.length = 13;

    // 生成する個数
    this.count = 2;

    // パスワードを表示するかどうか
    this.isViewpassword = false;

    this.set(init);
  }

  /**
   * generate random number
   * @returns {string}
   */
  grn() {
    return Array.from(cryptoJs.randomFillSync(new Uint8Array(this.length)))
      .map((n) => this.generateBaseStrs[n % this.generateBaseStrs.length])
      .join("");
  }

  /**
   * getRandomNumberItem
   * @param index
   * @returns {RandomNumberItemModel}
   */
  grni(index = 0) {
    return new RandomNumberItemModel({
      id: index,
      value: this.grn(),
      note: "",
    });
  }

  /**
   * getRandomNumberItems
   * @return {RandomNumberItemModel[]}
   */
  grnis() {
    const items = [...Array(this.count)].map((_, index) => {
      return this.grni(index);
    });

    return items;
  }

  set(init) {
    Object.assign(this, init);
  }
}
