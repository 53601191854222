<template>
  <div id="app-sidebar">
    <setting-input-organisms
      :setting="setting"
      :items="items"
      @onClickSubmit="onClickSubmit"
      @onPressClear="$emit('onPressClear')"
      @onChangeSetting="values => $emit('onChangeSetting', values)"
      ref="settingInputOrganismsRef"
    />
  </div>
</template>

<script>
import RandomNumberSettingModel from "@/models/RandomNumberSettingModel";
import SettingInputOrganisms from "@/components/organisms/SettingInputOrganisms";

export default {
  components: { SettingInputOrganisms },
  props: {
    setting: {
      type: RandomNumberSettingModel,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },

  methods: {
    /**
     *
     * @param {RandomNumberSettingModel} _setting
     */
    onClickSubmit(_setting) {
      this.$emit("onClickSubmit", _setting);
    },

    clear() {
      this.$refs.settingInputOrganismsRef.clear();
    }
  }
};
</script>

<style lang="scss">
#app-sidebar {
}
</style>
