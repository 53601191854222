import AppEnv from "@/consts/AppEnv";

export default {
  data: () => {
    return {
      configGeneral: AppEnv.getGeneralSetting(),
      configJwt: AppEnv.getJwtConfig(),
      configCrypto: AppEnv.getCryptConfig(),
    };
  },
};
