import tot from "@/config/tot";
import je from "@/config/je";

// NOTE: domain change
const nArr = [
  tot.t,
  tot.e,
  tot.m,
  tot.p,
  tot.o,
  tot.r,
  tot.a,
  tot.r,
  tot.y,
  tot.HYPHEN,
  tot.p,
  tot.a,
  tot.s,
  tot.s,
  tot.w,
  tot.o,
  tot.r,
  tot.d,
  tot.HYPHEN,
  tot.g,
  tot.e,
  tot.n,
  tot.e,
  tot.r,
  tot.a,
  tot.t,
  tot.o,
  tot.r,
  tot.DOT,
  tot.p,
  tot.a,
  tot.v,
  tot.e,
  tot.HYPHEN,
  tot.t,
  tot.h,
  tot.e,
  tot.HYPHEN,
  tot.w,
  tot.a,
  tot.y,
  tot.DOT,
  tot.w,
  tot.o,
  tot.r,
  tot.k,
];
const arr = "pg4SQ5IevsN8ROmkG1dYfxPiVCDXwqb9ByulLarz0toWchT73MEZj2UH6KFAnJ-.:";

export function eak() {
  const host = `${window.location.hostname}`;

  const str = nArr.map((word) => String.fromCharCode(word)).join("");
  if (host !== str) {
    throw Error("");
  }
  return true;
}

export function akb() {
  const host = `${window.location.hostname}`;

  // NOTE: domain change
  const tmp = [
    je.t,
    je.e,
    je.m,
    je.p,
    je.o,
    je.r,
    je.a,
    je.r,
    je.y,
    je["-"],
    je.p,
    je.a,
    je.s,
    je.s,
    je.w,
    je.o,
    je.r,
    je.d,
    je["-"],
    je.g,
    je.e,
    je.n,
    je.e,
    je.r,
    je.a,
    je.t,
    je.o,
    je.r,
    je["."],
    je.p,
    je.a,
    je.v,
    je.e,
    je["-"],
    je.t,
    je.h,
    je.e,
    je["-"],
    je.w,
    je.a,
    je.y,
    je["."],
    je.w,
    je.o,
    je.r,
    je.k,
  ];

  const str = tmp
    .map((_, index) =>
      String.fromCharCode(arr.charAt(tmp[index]).charCodeAt(0))
    )
    .join("");
  if (host !== str) {
    throw Error("");
  }

  return true;
}
