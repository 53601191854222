export default class RandomNumberValidate {
  /**
   *
   * @param {RandomNumberSettingModel} setting
   * @param {RandomNumberItemModel[]} items
   * @param { RandomNumberSecretModel} secret
   * @return {string}
   */
  static validate(setting, items, secret) {
    if (items.length === 0) {
      return "パスワードが生成されていません。「実行」ボタンを押して、一時パスワードを生成してください。";
    }

    if (secret.isUsePassword) {
      if (secret.password == null || secret.password === "") {
        return "パスワード付きで共有する場合、パスワードを入力してください。";
      }
    }

    return "";
  }
}
