export default {
  p: 0,
  g: 1,
  4: 2,
  S: 3,
  Q: 4,
  5: 5,
  I: 6,
  e: 7,
  v: 8,
  s: 9,
  N: 10,
  8: 11,
  R: 12,
  O: 13,
  m: 14,
  k: 15,
  G: 16,
  1: 17,
  d: 18,
  Y: 19,
  f: 20,
  x: 21,
  P: 22,
  i: 23,
  V: 24,
  C: 25,
  D: 26,
  X: 27,
  w: 28,
  q: 29,
  b: 30,
  9: 31,
  B: 32,
  y: 33,
  u: 34,
  l: 35,
  L: 36,
  a: 37,
  r: 38,
  z: 39,
  0: 40,
  t: 41,
  o: 42,
  W: 43,
  c: 44,
  h: 45,
  T: 46,
  7: 47,
  3: 48,
  M: 49,
  E: 50,
  Z: 51,
  j: 52,
  2: 53,
  U: 54,
  H: 55,
  6: 56,
  K: 57,
  F: 58,
  A: 59,
  n: 60,
  J: 61,
  "-": 62,
  ".": 63,
  ":": 64,
};
