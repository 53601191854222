export const ge = [
  process.env.VUE_APP_E_GR,
  process.env.VUE_APP_E_BL,
  process.env.VUE_APP_E_RD,
  process.env.VUE_APP_E_BK,
  process.env.VUE_APP_E_WE,
  process.env.VUE_APP_E_YL,
  process.env.VUE_APP_E_PK,
  process.env.VUE_APP_E_OG,
  process.env.VUE_APP_E_GY,
  process.env.VUE_APP_E_PP,
  process.env.VUE_APP_E_BW,
  process.env.VUE_APP_E_IR,
  process.env.VUE_APP_E_LC,
  process.env.VUE_APP_E_SM,
  process.env.VUE_APP_E_DS,
  process.env.VUE_APP_E_LS,
  process.env.VUE_APP_E_CN,
  process.env.VUE_APP_E_LB,
  process.env.VUE_APP_E_PB,
  process.env.VUE_APP_E_SB,
  process.env.VUE_APP_E_DB,
  process.env.VUE_APP_E_CB,
  process.env.VUE_APP_E_DG,
  process.env.VUE_APP_E_LG,
];

export const ga = [
  process.env.VUE_APP_A_AF,
  process.env.VUE_APP_A_CM,
  process.env.VUE_APP_A_GH,
  process.env.VUE_APP_A_SH,
  process.env.VUE_APP_A_SO,
  process.env.VUE_APP_A_RD,
  process.env.VUE_APP_A_ST,
  process.env.VUE_APP_A_AH,
  process.env.VUE_APP_A_GS,
  process.env.VUE_APP_A_ER,
  process.env.VUE_APP_A_LP,
  process.env.VUE_APP_A_WR,
  process.env.VUE_APP_A_NA,
  process.env.VUE_APP_A_HH,
  process.env.VUE_APP_A_RS,
  process.env.VUE_APP_A_NC,
  process.env.VUE_APP_A_LB,
  process.env.VUE_APP_A_PF,
  process.env.VUE_APP_A_RG,
  process.env.VUE_APP_A_BC,
  process.env.VUE_APP_A_BB,
  process.env.VUE_APP_A_MH,
  process.env.VUE_APP_A_WP,
  process.env.VUE_APP_A_NA,
];

export const gi = [
  process.env.VUE_APP_I_VN,
  process.env.VUE_APP_I_VL,
  process.env.VUE_APP_I_CO,
  process.env.VUE_APP_I_CB,
  process.env.VUE_APP_I_GT,
  process.env.VUE_APP_I_FL,
  process.env.VUE_APP_I_KT,
  process.env.VUE_APP_I_HP,
  process.env.VUE_APP_I_UL,
  process.env.VUE_APP_I_SS,
  process.env.VUE_APP_I_CN,
  process.env.VUE_APP_I_SP,
  process.env.VUE_APP_I_OB,
  process.env.VUE_APP_I_BS,
  process.env.VUE_APP_I_FT,
  process.env.VUE_APP_I_PL,
  process.env.VUE_APP_I_RD,
  process.env.VUE_APP_I_SH,
  process.env.VUE_APP_I_OR,
  process.env.VUE_APP_I_WS,
  process.env.VUE_APP_I_TP,
  process.env.VUE_APP_I_FH,
  process.env.VUE_APP_I_EN,
  process.env.VUE_APP_I_TB,
  process.env.VUE_APP_I_SP,
  process.env.VUE_APP_I_HN,
  process.env.VUE_APP_I_WF,
  process.env.VUE_APP_I_TA,
  process.env.VUE_APP_I_XP,
  process.env.VUE_APP_I_BL,
];

export const gs = [
  process.env.VUE_APP_S_BB,
  process.env.VUE_APP_S_SB,
  process.env.VUE_APP_S_FB,
  process.env.VUE_APP_S_VB,
  process.env.VUE_APP_S_BVB,
  process.env.VUE_APP_S_TS,
  process.env.VUE_APP_S_BT,
  process.env.VUE_APP_S_HB,
  process.env.VUE_APP_S_TT,
  process.env.VUE_APP_S_RB,
  process.env.VUE_APP_S_GF,
  process.env.VUE_APP_S_HC,
  process.env.VUE_APP_S_AT,
  process.env.VUE_APP_S_SM,
  process.env.VUE_APP_S_MS,
  process.env.VUE_APP_S_SS,
  process.env.VUE_APP_S_AS,
  process.env.VUE_APP_S_DG,
  process.env.VUE_APP_S_WP,
  process.env.VUE_APP_S_GA,
  process.env.VUE_APP_S_GR,
  process.env.VUE_APP_S_JD,
  process.env.VUE_APP_S_KT,
  process.env.VUE_APP_S_WL,
  process.env.VUE_APP_S_BG,
];
