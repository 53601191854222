import RandomNumberConfig from "@/config/RandomNumberConfig";

export default class RandomNumberHelper {
  /**
   *
   * @return {{name: string, value: number}[]}
   */
  static getGenerateIncludes() {
    return [
      { name: "数字", value: RandomNumberConfig.GenerateIncludes.NUMBER },
      { name: "小文字", value: RandomNumberConfig.GenerateIncludes.LOWER_CASE },
      { name: "大文字", value: RandomNumberConfig.GenerateIncludes.UPPER_CASE },
      { name: "記号", value: RandomNumberConfig.GenerateIncludes.SYMBOL },
    ];
  }

  static getGenerateBaseStrs() {
    return RandomNumberConfig.GenerateBasesStrs;
  }

  /**
   * チェックが入っているincludesの要素番号を取得する
   * @param {boolean[]} checks チェックボックスのtrue, falseの配列
   * @return {number[]} includesの配列
   */
  static getCheckedIncludes(checks) {
    if (!Array.isArray(checks) || checks.length === 0) return [];
    const includes = RandomNumberHelper.getGenerateIncludes();

    const result = includes
      .map((include, index) => {
        if (checks[index] != null && checks[index] === true) {
          return include.value;
        }
      })
      .filter((v) => v != null);
    if (result) {
      return result;
    }
    return [];
  }

  /**
   * includes配列から、表示文字列配列を取得する
   * @param {number[]} includes
   * @return {string[]}
   */
  static getIncludesStrs(includes) {
    if (!Array.isArray(includes) || includes.length === 0) return [];
    const baseStrs = RandomNumberHelper.getGenerateBaseStrs();
    return includes.map((include) => {
      return baseStrs[include];
    });
  }

  /**
   * includes配列から、テキストエリアに表示する文字を取得する
   * @param {number[]} includes
   * @returns {string}
   */
  static getIncludesStr(includes) {
    const baseStrs = RandomNumberHelper.getIncludesStrs(includes);
    return baseStrs.join("");
  }

  static getRandomNumber() {}
}
