import * as zLib from "zlib";

export default class ZLibUtil {
  /**
   * deflate
   * @param str
   * @returns {Buffer}
   */
  static d(str) {
    const buf = Buffer.from(str, "utf8");
    // const gzip = new zLib.gzip(buf);
    // return gzip.compress();
    // zLib.d
    return zLib.deflateRawSync(buf);
  }

  /**
   * inflate
   * @param deflated
   * @returns {string}
   */
  static i(deflated) {
    const inflated = zLib.inflateRawSync(deflated);
    return inflated.toString("utf8");
  }
}
