export default class AppEnv {
  static getGeneralSetting() {
    return {
      projectName: process.env.VUE_APP_PROJECT_NAME,
      appUrl: process.env.VUE_APP_URL,
    };
  }

  static getJwtConfig() {
    return {
      jwtSecret: process.env.VUE_APP_JWT_SECRET,
      jwtSecret2: process.env.VUE_APP_JWT_SECRET2,
      jwtSecret3: process.env.VUE_APP_JWT_SECRET3,
      jwtSecret4: process.env.VUE_APP_JWT_SECRET4,
      jwtSecret5: process.env.VUE_APP_JWT_SECRET5,
      jwtHide: process.env.VUE_APP_JWT_HIDE,
    };
  }

  static getCryptConfig() {
    return {
      secret: process.env.VUE_APP_CRYPTO_SECRET,
      secret2: process.env.VUE_APP_CRYPTO_SECRET2,
      secret3: process.env.VUE_APP_CRYPTO_SECRET3,
      secret4: process.env.VUE_APP_CRYPTO_SECRET4,
      secret5: process.env.VUE_APP_CRYPTO_SECRET5,
      bufferKey: process.env.VUE_APP_CRYPTO_BUFFER_KEY,
      bufferKey2: process.env.VUE_APP_CRYPTO_BUFFER_KEY2,
      bufferKey3: process.env.VUE_APP_CRYPTO_BUFFER_KEY3,
      bufferKey4: process.env.VUE_APP_CRYPTO_BUFFER_KEY4,
      bufferKey5: process.env.VUE_APP_CRYPTO_BUFFER_KEY5,
      method: process.env.VUE_APP_CRYPTO_METHOD,
      encoding: process.env.VUE_APP_CRYPTO_ENCODING,
      passCodeSalt: process.env.VUE_APP_CRYPTO_PASSCODE_SALT,
      passCodeSalt2: process.env.VUE_APP_CRYPTO_PASSCODE_SALT2,
      passCodeSalt3: process.env.VUE_APP_CRYPTO_PASSCODE_SALT3,
      passCodeSalt4: process.env.VUE_APP_CRYPTO_PASSCODE_SALT4,
      passCodeSalt5: process.env.VUE_APP_CRYPTO_PASSCODE_SALT5,
    };
  }

  static getDomainSetting() {
    return {
      domainCheck: process.env.VUE_APP_DOMAIN_CHECK,
    };
  }

  static getSecuritySetting() {
    return {
      d: process.env.VUE_APP_DOMAIN_SECURITY,
    };
  }
}
