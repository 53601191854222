import AppEnv from "@/consts/AppEnv";

export default class Controller {
  static cd() {
    // NOTE: domain change
    const host = `${window.location.host}`;
    const envHost = AppEnv.getDomainSetting().domainCheck;

    if (host !== envHost) throw Error("error");
  }
}
