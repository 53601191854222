<template>
  <v-card :color="cardBgColor" dark id="setting-input-organisms">
    <v-card-title class="setting-input-title">
      パスワード生成ルール設定
    </v-card-title>
    <v-container>
      <form>
        <!-- 使う文字 -->
        <v-row class="setting-input-checkbox-row">
          <v-col
            sm="6"
            v-for="(checkbox,
            index) in RandomNumberHelper.getGenerateIncludes()"
            :key="index"
          >
            <v-checkbox
              v-model="checkboxes[index]"
              :label="checkbox.name"
              :name="`checkBox_${index}`"
              class="setting-input-checkbox"
              :messages="undefined"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row class="setting-input-textarea-row">
          <v-col>
            <v-textarea
              v-model="baseStrs"
              type="text"
              name="baseStr"
              auto-grow
              counter="100"
              rows="3"
              label="自動生成で使用する文字(変更可能)"
              hint="ここに表示されている文字の中からランダムで文字を作成します。空白やタブ、改行は入力できません。"
              @keyup="onBaseStrsKeyManage"
              :background-color="inputBgColor"
              class="setting-input-area"
            />
          </v-col>
        </v-row>

        <v-row class="setting-input-numbers-row">
          <!-- 桁数 -->
          <v-col sm="6">
            <v-text-field
              v-model.number="length"
              type="number"
              max="999"
              min="1"
              label="桁数"
              :background-color="inputBgColor"
            />
          </v-col>

          <!-- 生成する個数 -->
          <v-col sm="6">
            <v-text-field
              v-model.number="count"
              type="number"
              max="999"
              min="1"
              label="生成する個数"
              :background-color="inputBgColor"
            />
          </v-col>
        </v-row>

        <!-- ボタン -->
        <v-row
          class="setting-button-row"
          justify="space-between"
          align="center"
          align-content="center"
        >
          <v-col sm="6" align-self="center">
            <v-btn color="primary" @click="onPressSubmit">
              実行
            </v-btn>
          </v-col>
          <v-col sm="6">
            <v-btn color="warning" @click="onPressClear">
              リセット
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-container>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import RandomNumberHelper from "@/helper/RandomNumberHelper";
import RandomNumberSettingModel from "@/models/RandomNumberSettingModel";
import KeyboardHelper from "@/helper/KeyboardHelper";

export default {
  mixins: [validationMixin],

  props: {
    setting: {
      type: RandomNumberSettingModel,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    baseStrs: "",
    checkboxes: RandomNumberHelper.getGenerateIncludes().map(() => false),
    RandomNumberHelper: RandomNumberHelper,
    length: 10,
    count: 1,

    inputBgColor: "white",
    cardBgColor: "rgb(53 172 232)"
  }),

  created() {
    this.clear();
  },

  watch: {
    /**
     * @param {RandomNumberSettingModel} val
     */
    setting(val) {
      this.length = val.length;
      this.count = val.count;
      // this.checkboxes = this.getCheckBoxListBySetting(val);
      this.baseStrs = val.generateBaseStrs;
    },

    checkboxes() {
      this.onChangeCheckBox();
    }
  },

  methods: {
    onBaseStrsKeyManage() {
      this.baseStrs = KeyboardHelper.textDeleteBlockCode(this.baseStrs);
      this.$emit("onChangeSetting", {
        baseStr: this.baseStrs
      });
    },

    /**
     * 実行をクリック
     */
    onPressSubmit() {
      this.$emit("onClickSubmit", this.getCurrentSetting());
      // this.$v.$touch();
    },

    /**
     * クリアをクリック
     */
    onPressClear() {
      this.$emit("onPressClear");
    },

    /**
     * チェックボックスを更新
     */
    onChangeCheckBox() {
      // テキストを更新
      const includes = RandomNumberHelper.getCheckedIncludes(this.checkboxes);
      this.baseStrs = RandomNumberHelper.getIncludesStr(includes);

      // 親にイベント通知
      /*
      this.$emit("onChangeSetting", {
        checkboxes: this.checkboxes
      });
      */
    },

    /**
     * 最新値を取得
     */
    getCurrentSetting() {
      const setting = new RandomNumberSettingModel({
        generateIncludes: RandomNumberHelper.getCheckedIncludes(
          this.checkboxes
        ),
        generateBaseStrs: this.baseStrs,
        length: this.length,
        count: this.count
      });

      return setting;
    },

    /**
     * settingからチェックボックスの配列を生成する
     * @param {RandomNumberSettingModel} setting
     */
    getCheckBoxListBySetting(setting) {
      const baseArr = RandomNumberHelper.getGenerateIncludes().map(() => false);
      setting.generateIncludes.forEach((item, index) => {
        baseArr[index] = setting.generateIncludes.includes(index);
      });

      return baseArr;
    },

    /**
     * 入力値を初期化する
     */
    clear() {
      const setting = new RandomNumberSettingModel();
      this.length = setting.length;
      this.count = setting.count;
      this.checkboxes = this.getCheckBoxListBySetting(setting);
      this.baseStrs = this.setting.generateBaseStrs;
    }
  }
};
</script>

<style lang="scss">
@mixin settingInputAreaMixin() {
  color: black;
  caret-color: black;
  padding: 8px;
  border-radius: 4px;
}

#setting-input-organisms {
  .setting-input-title {
    text-align: center;
  }

  .setting-input-checkbox-row {
    // background-color: rgb(80, 129, 153);

    .setting-input-checkbox {
      margin: 0px !important;

      .v-input__slot {
        margin-bottom: 0px;
      }

      .v-messages {
        display: none;
      }

      label {
        // font-weight: bold;
        color: white;
      }
    }
  }

  .setting-input-textarea-row {
    .v-label {
      top: -4px;
    }
    textarea {
      @include settingInputAreaMixin();
    }
  }

  .setting-input-numbers-row {
    .v-label {
      top: -4px;
    }
    input {
      @include settingInputAreaMixin();
    }
  }

  .setting-button-row {
    font-weight: bold;

    > div:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  .v-label {
    color: white;
  }
}
</style>
