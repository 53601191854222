import { saveAs } from "file-saver";

export default class DownloadHelper {
  /**
   *
   * @param {RandomNumberItemModel[]} items
   */
  static download(items) {
    if (items == undefined || !Array.isArray(items)) return;

    const filename = "password.txt";
    let file = "number,password,memo\n";
    items.forEach((item, index) => {
      file = `${file}${index},${item.value},${item.note}\n`;
    });
    const blob = new Blob([file], { type: "text/plain;charset=utf-8" });

    saveAs(blob, filename);
  }
}
