var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"random-number-list organisms"}},[_c('v-card-text',[_c('v-simple-table',{attrs:{"fixed-header":"","height":"452px"}},[[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("生成値")]),_c('th',{staticClass:"text-left"},[_vm._v("メモ")]),_c('th')])]),_c('tbody',[_vm._l((_vm.randomNumberItems),function(item,index){return _c('tr',{key:item.id,staticClass:"table-list-item"},[_c('td',[_c('v-text-field',{attrs:{"placeholder":"生成された文字","type":_vm.setting.isViewpassword ? 'text' : 'password'},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},[_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.$emit('onClickListItemValueCopy', {
                          item: item,
                          index: index
                        })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v("クリップボードにコピー")])])],1)],1),_c('td',[_c('v-text-field',{attrs:{"placeholder":"メモを記入"},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})],1),_c('td',[_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"append","text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.$emit('onClickListItemDelete', {
                        item: item,
                        index: index
                      })}},slot:"append"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-trash-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v("削除する")])])],1)])}),(_vm.items.length > 0)?_c('tr',{staticClass:"table-list-item"},[_c('td',[_c('v-btn',{attrs:{"tile":"","color":"success"},on:{"click":function($event){return _vm.$emit('onClickAddItem')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa-plus-circle ")]),_vm._v(" 追加 ")],1)],1),_c('td',{attrs:{"colspan":"2"}},[_c('v-switch',{attrs:{"label":"パスワードを表示する"},model:{value:(_vm.setting.isViewpassword),callback:function ($$v) {_vm.$set(_vm.setting, "isViewpassword", $$v)},expression:"setting.isViewpassword"}})],1)]):_vm._e()],2)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }