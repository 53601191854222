<template>
  <div id="app-footer">
    <v-container>
      <v-row no-gutters>
        <v-col md="5" offset-md="1" xs="12">
          <ul>
            <li>
              <router-link to="/about#about"
                >「一時パスワード共有サービス」とは？</router-link
              >
            </li>
            <li>
              <router-link to="/about#disclaimer">免責事項</router-link>
            </li>
            <li>
              <router-link to="/about#copyright">著作権</router-link>
            </li>
          </ul>
        </v-col>
        <v-col md="5" xs="12">
          <p>
            created by
            <a
              href="https://twitter.com/m_hirasaki1985"
              target="_blank"
              rel="noopener noreferrer"
            >
              @m_hirasaki1985
            </a>
          </p>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col md="12" xs="12" align-self="center" offset="4" offset-md="5"
          ><p>© 2021 m_hirasaki1985</p></v-col
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#app-footer {
  background-color: #f2f2f2;

  a {
    &:hover {
      color: blue;
    }
  }
}
</style>
