import ZLibUtil from "@/util/ZLibUtil";
import StringEncryptUtil from "@/util/StringEncryptUtil";

const arr2 = "quAQaDyRWFd36vlUEbhjkOBwizpYM72TgVxK0f4St5CImZePN1JHXGrL9o8scn";
const arr3 = "od8sXrLW3tKU24yqxhRunwJCAOHpeYvTB0bFSzgaim9kMNPjlfZVED75Q16IcG";

export function mix() {
  const host = window.location.hostname;
  const h = ZLibUtil.d(host);

  // NOTE: domain change
  return [
    43,
    73,
    205,
    45,
    200,
    47,
    74,
    44,
    170,
    212,
    45,
    72,
    44,
    46,
    46,
    207,
    47,
    74,
    209,
    77,
    79,
    205,
    75,
    45,
    74,
    44,
    201,
    47,
    210,
    43,
    72,
    44,
    75,
    213,
    45,
    201,
    72,
    213,
    45,
    79,
    172,
    212,
    3,
    202,
    101,
    3,
    0,
  ].every((num, index) => num === h[index]);
}

export function mixes() {
  try {
    const host = window.location.hostname;
    const j = StringEncryptUtil.ges(host);

    return j.length === 0 && j === arr2 + arr3;
  } catch (e) {
    return true;
  }
}
