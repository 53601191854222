/**
 * @type {string[]}
 */
export const np = [
  process.env.VUE_APP_N_AA,
  process.env.VUE_APP_N_AB,
  process.env.VUE_APP_N_AC,
  process.env.VUE_APP_N_AD,
  process.env.VUE_APP_N_AE,
  process.env.VUE_APP_N_AF,
  process.env.VUE_APP_N_AG,
  process.env.VUE_APP_N_AH,
  process.env.VUE_APP_N_AI,
  process.env.VUE_APP_N_AJ,
  process.env.VUE_APP_N_AK,
  process.env.VUE_APP_N_AL,
  process.env.VUE_APP_N_AM,
  process.env.VUE_APP_N_AN,
  process.env.VUE_APP_N_AO,
  process.env.VUE_APP_N_AP,
  process.env.VUE_APP_N_AQ,
  process.env.VUE_APP_N_AR,
  process.env.VUE_APP_N_AS,
  process.env.VUE_APP_N_AT,
];
