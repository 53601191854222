import * as pako from "pako";
import { np, pro } from "@/plugins/appPlugin";

export default class CompressStrUtil {
  /**
   * 文字列を圧縮する
   * @param {string} str
   * @returns {string}
   */
  static deflate(str) {
    try {
      if (!pro()) return "";
      const gzip = pako.gzip(str, { to: "string" });

      if (!np()) return "";
      return window.btoa(gzip);
    } catch (e) {
      return "";
    }
  }

  /**
   * 文字列を伸張する
   * @param {string} compressed
   * @returns {string}
   */
  static inflate(compressed) {
    try {
      if (!pro()) return "";
      const buf = window.atob(compressed);
      return pako.ungzip(buf, { to: "string" });
    } catch (e) {
      return "";
    }
  }
}
