<template>
  <v-row id="home-container">
    <v-col cols="12" sm="4">
      <AppSidebar
        :setting="setting"
        :items="items"
        @onClickSubmit="onClickSubmit"
        @onPressClear="onPressSidebarReset"
        @onChangeSetting="onChangeSideBarSetting"
        ref="appSidebarRef"
      />
    </v-col>
    <v-col cols="12" sm="8">
      <v-row>
        <v-col cols="12">
          <RandomNumberListOrganisms
            :setting="setting"
            :items="items"
            @onClickListItemValueCopy="onClickListItemValueCopy"
            @onClickListItemMemoCopy="onClickListItemMemoCopy"
            @onClickListItemDelete="onClickListItemDelete"
            @onClickAddItem="onClickAddItem"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <RandomNumberFooterOrganisms
            :setting="setting"
            :secret-model="secretModel"
            :items="items"
            :share-url="shareUrl"
            @onClickUrlShare="onClickUrlShare"
            @onClickShareUrlCopy="onClickShareUrlCopy"
            @onClickDownload="onClickDownload"
          />
        </v-col>
      </v-row>

      <PasswordCheckModal
        v-if="isViewPasswordCheckModal"
        :dialog="isViewPasswordCheckModal"
        :modalTitle="modalTitle"
        @onClickSubmit="onClickSubmitModal"
        @onClickClose="onClickCloseModal"
      />
    </v-col>
  </v-row>
</template>

<script>
import RandomNumberSettingModel from "@/models/RandomNumberSettingModel";
import RandomNumberListOrganisms from "@/components/organisms/RandomNumberListOrganisms";
import RandomNumberFooterOrganisms from "@/components/organisms/RandomNumberFooterOrganisms";
import AppConfigMixin from "@/mixins/AppConfigMixin";
import UrlHelper from "@/helper/UrlHelper";
import RandomNumberSecretModel from "@/models/RandomNumberSecretModel";
import PasswordCheckModal from "@/components/modals/PasswordCheckModal";
import JwtUtil from "@/util/JwtUtil";
import AppSidebar from "@/components/layout/AppSidebar";
import RandomNumberValidate from "@/modules/RandomNumberValidate";
import RandomNumberHelper from "@/helper/RandomNumberHelper";
import StringEncryptUtil from "@/util/StringEncryptUtil";
import SM from "@/helper/SM";
import DownloadHelper from "@/helper/DownloadHelper";
import Controller from "@/controllers/Controller";

export default {
  name: "Home",
  mixins: [AppConfigMixin],
  components: {
    AppSidebar,
    PasswordCheckModal,
    RandomNumberFooterOrganisms,
    RandomNumberListOrganisms
  },

  data: () => ({
    setting: new RandomNumberSettingModel(),

    /**
     * @type {RandomNumberItemModel[]}
     */
    items: [],

    secretModel: new RandomNumberSecretModel(),

    shareUrl: "",

    /**
     * @type {{
     *   hashStr: string,
     *   isUsePassword: boolean
     * }}
     */
    tempSecretHashObj: {},
    isViewPasswordCheckModal: false,
    modalTitle: "復号用パスワードを入力してください"
  }),

  mounted() {
    try {
      Controller.cd();
      const params = this.$route.query;
      const obj = UrlHelper.gdco(params || { q: "" });

      if (
        obj.isUsePassword != null &&
        obj.isUsePassword === true &&
        obj.hashStr &&
        obj.hashStr !== ""
      ) {
        this.tempSecretHashObj = obj;
        this.showPasswordCheckModal();
      } else {
        this.setting = obj.setting;
        this.items = obj.items;
      }
    } catch (e) {
      return;
    }
  },

  watch: {
    secretModel() {
      try {
        this.shareUrl = "";
      } catch (e) {
        return;
      }
    }
  },

  methods: {
    /**
     * @params {{baseStr: string, checkboxes: []}} params
     */
    onChangeSideBarSetting(params) {
      try {
        Controller.cd();
        if (params.baseStr) {
          this.setting.generateBaseStrs = params.baseStr;
        }
        if (params.checkboxes) {
          const includes = RandomNumberHelper.getCheckedIncludes(
            params.checkboxes
          );
          this.setting.generateIncludes = includes;
          this.setting.generateBaseStrs = RandomNumberHelper.getIncludesStr(
            includes
          );
        }
      } catch (e) {
        return;
      }
    },

    /**
     * 実行ボタンが押されたとき
     * @param {RandomNumberSettingModel} setting
     */
    onClickSubmit(setting) {
      try {
        Controller.cd();
        // validate
        if (
          setting.generateBaseStrs == null ||
          setting.generateBaseStrs === ""
        ) {
          this.$toasted.error(
            "パスワード生成で使用する文字が入力されていません。"
          );
          return;
        }

        // 乱数生成
        const items = setting.grnis();
        this.setting = setting;
        this.items = items;

        // this.fetchShareUrl();
        this.shareUrl = "";
      } catch (e) {
        return;
      }
    },

    /**
     * 初期化ボタンが押された時
     */
    onPressSidebarReset() {
      try {
        Controller.cd();
        this.clear();
      } catch (e) {
        return;
      }
    },

    clear() {
      try {
        Controller.cd();
        const setting = new RandomNumberSettingModel();

        this.setting = setting;
        this.secretModel = new RandomNumberSecretModel();
        this.items = [];
        this.shareUrl = "";

        this.$refs.appSidebarRef.clear();
      } catch (e) {
        return;
      }
    },

    /**
     * 乱数コピーをクリック
     * @param {{item: RandomNumberItemModel, index: number}} item
     */
    onClickListItemValueCopy(item) {
      try {
        Controller.cd();
        this.$copyText(item.item.value).then(() => {
          this.$toasted.show("クリップボードに生成値をコピーしました！");
        });
      } catch (e) {
        return;
      }
    },

    /**
     * メモコピーをクリック
     * @param {{item: RandomNumberItemModel, index: number}} item
     */
    onClickListItemMemoCopy(item) {
      try {
        Controller.cd();
        this.$copyText(item.item.note).then(() => {
          this.$toasted.show("クリップボードにメモをコピーしました！");
        });
      } catch (e) {
        return;
      }
    },

    /**
     * 削除アイコンクリック
     * @param {{item: RandomNumberItemModel, index: number}} item
     */
    onClickListItemDelete(item) {
      try {
        Controller.cd();
        this.items.splice(item.index, 1);
        // this.fetchShareUrl();
        this.shareUrl = "";
      } catch (e) {
        return;
      }
    },

    /**
     * 追加アイコンクリック
     */
    onClickAddItem() {
      try {
        Controller.cd();
        const item = this.setting.grni(this.items.length);
        this.items.push(item);
        // this.fetchShareUrl();
        this.shareUrl = "";
      } catch (e) {
        return;
      }
    },

    /**
     * 共有URLのコピーが押されたとき
     * @param {string} url
     */
    onClickShareUrlCopy(url) {
      try {
        Controller.cd();
        this.$copyText(url).then(() => {
          this.$toasted.show("クリップボードに共有URLをコピーしました！");
        });
      } catch (e) {
        return;
      }
    },

    /**
     * URLで共有ボタンが押されたとき
     * @param {{isUsePassword: boolean, password: string}} params
     * @returns {Promise<void>}
     */
    onClickUrlShare(params) {
      try {
        Controller.cd();
        this.secretModel.isUsePassword = params.isUsePassword;
        this.secretModel.password = params.password;

        const message = RandomNumberValidate.validate(
          this.setting,
          this.items,
          this.secretModel
        );
        if (message === "") {
          this.fetchShareUrl();
        } else {
          this.$toasted.error(message);
        }
      } catch (e) {
        return;
      }
    },

    /**
     * ダウンロードボタンが押されたとき
     */
    onClickDownload() {
      try {
        Controller.cd();
        const message = RandomNumberValidate.validate(
          this.setting,
          this.items,
          this.secretModel
        );
        if (message === "") {
          DownloadHelper.download(this.items);
        } else {
          this.$toasted.error(message);
        }
      } catch (e) {
        return;
      }
    },

    /**
     * URLを更新する
     */
    fetchShareUrl() {
      try {
        Controller.cd();
        const compressedStr = UrlHelper.gcs(
          this.setting,
          this.items,
          this.secretModel
        );
        this.shareUrl = UrlHelper.gau(compressedStr);
        this.$router.push(UrlHelper.gsu(compressedStr));
      } catch (e) {
        return;
      }
    },

    showPasswordCheckModal() {
      try {
        Controller.cd();
        this.isViewPasswordCheckModal = true;
      } catch (e) {
        return;
      }
    },

    /**
     * モーダルの送信ボタンが押されたとき
     * @param {{password: string}} params
     */
    onClickSubmitModal(params) {
      try {
        Controller.cd();
        this.isViewPasswordCheckModal = false;

        const result = JwtUtil.dj(
          this.tempSecretHashObj.hashStr,
          StringEncryptUtil.gpw(params.password, SM.gps()),
          SM.gjps()
        );

        if (result.hasError) {
          this.modalTitle = "パスワードが違います。もう一度入力してください。";
          this.isViewPasswordCheckModal = true;
        } else {
          this.setting = result.setting;
          this.items = result.items;
        }
      } catch (e) {
        return;
      }
    },

    /**
     * モーダルがクローズされたとき
     */
    onClickCloseModal() {
      try {
        Controller.cd();
        this.isViewPasswordCheckModal = false;
      } catch (e) {
        return;
      }
    }
  }
};
</script>
