import Vue from "vue";
import VueRouter from "vue-router";
import Vuelidate from "vuelidate";
import VueClipboard from "vue-clipboard2";
import Toasted from "vue-toasted";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.use(VueRouter);
Vue.use(Toasted, {
  position: "top-center",
  duration: 2000,
  type: "success",
});
Vue.use(VueClipboard);
Vue.use(Vuelidate);
Vue.config.productionTip = false;

// css
import "@fortawesome/fontawesome-free/css/all.css";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
