<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          パスワード確認
        </v-btn>
      </template>
      <v-card>
        <v-form @submit="onClickSubmit">
          <!-- title -->
          <v-card-title>
            <span class="headline">{{ modalTitle }}</span>
          </v-card-title>
          <!-- body -->
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    autofocus
                    label="Password*"
                    type="password"
                    v-model="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onClickClose">
              閉じる
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="password == null || password === ''"
              @click="onClickSubmit"
            >
              復号する
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    }
  },

  data: () => {
    return {
      password: ""
    };
  },

  methods: {
    /**
     *
     * @param {KeyboardEvent} e
     */
    onClickSubmit(e) {
      if (this.password && this.password !== "") {
        this.$emit("onClickSubmit", { password: this.password });
      }
      e.preventDefault();
    },

    onClickClose() {
      this.$emit("onClickClose");
    }
  }
};
</script>

<style lang="scss" scoped></style>
