import UrlHelper from "@/helper/UrlHelper";

export default class DatePickUtil {
  /**
   * get domain code
   */
  static gdc() {
    const domain = UrlHelper.gcn();
    const numRes = domain.replace(/[^0-9]/g, "");
    const lowerRes = domain.replace(/[^a-z]/g, "");
    const naz = [3, 5, 7, 9, 1, 5, 2, 3];
    const naz2 = [9, 5, 6, 8, 1, 2, 6, 4];

    let lNum = 0;
    lowerRes.split("").forEach((val, index) => {
      const before =
        lowerRes.charAt(index - 1) !== "" ? lowerRes.charAt(index - 1) : "a";
      lNum += (val.charCodeAt(0) + before.charCodeAt(0)) / 2;
    });

    let nNum = 0;
    if (numRes && numRes !== "") {
      numRes.split("").forEach((val, index) => {
        const before =
          lowerRes.charAt(index - 1) !== "" ? lowerRes.charAt(index - 1) : "a";
        nNum += (val.charCodeAt(0) + before.charCodeAt(0)) / 2;
      });
    }

    if (Number.isNaN(lNum)) {
      lNum = 0;
    }
    if (Number.isNaN(nNum)) {
      nNum = 0;
    }

    return {
      lNum: Math.floor(
        naz.reduce((current, val, index) => {
          return (
            current + Math.floor(nNum + Math.sin(lNum) + val * index) / val
          );
        })
      ),
      nNum: Math.floor(
        naz2.reduce((current, val, index) => {
          return (
            current + Math.floor(lNum + Math.cos(nNum) + val * index) / val
          );
        })
      ),
      qNum: Math.floor(
        naz.reduce((current, val, index) => {
          return (
            current + Math.floor(lNum + Math.cos(lNum) + val * index) / val
          );
        })
      ),
      zNum: Math.floor(
        naz.reduce((current, val, index) => {
          return (
            current + Math.floor(nNum + Math.sin(nNum) + val * index) / val
          );
        })
      ),
    };
  }
}
