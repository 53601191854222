export default class KeyboardHelper {
  static blockKeyboards = ["\t", " ", "　", "\n", "\r\n"];

  /**
   *
   * @param {KeyboardEvent} event
   */
  static blockTextAreaEvent(event) {
    if (event == null) return true;
  }

  /**
   *
   * @param {string} text
   */
  static textDeleteBlockCode(text) {
    let tmp = text;

    KeyboardHelper.blockKeyboards.forEach((item) => {
      tmp = tmp.split(item).join("");
    });

    return tmp;
  }
}
